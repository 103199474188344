<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h4 class="text-primary " style="font-weight:400" >Inscriptions</h4>
        </div>
        <div class="card mb-4 px-0 py-0" style="border-radius:10px">
            <div class="card-body px-0 py-0">

                <div class="tec-search-v-icon">
                    <input   v-model="dataPagination.searchQuery" type="search" ref="searchbar" name="search" placeholder="recherche ..." id="tec-search-v" class="tec-search-v">
                    <i class="fa fa-search fa-md fa-fw" aria-hidden="true"></i>
                </div>

            </div>
        </div>
        <div class="card" v-if="empty" v-loading="loading" >
            <div class="card-body text-center">
                <div  class="d-flex justify-content-center w-100 p-5">
                    <img  class="w-50" :src="require('../../../../assets/svg/empty_streec.svg')"   alt="" srcset="">
                </div>
                    <h6>Aucune donnée</h6>
            </div>
        </div>
        <div class="" v-if="!empty" v-loading="loading" >
            <div class="card mb-3" style="border-radius:10px!important;box-shadow:0px 0px 4px 1px rgb(0,0,0,0.15) !important"  v-for="row in queriedData" :key="row.id">
                <div class="card-body py-3 pl-2 d-flex align-items-center justify-content-between" >
                    <div style="max-width:90%">
                        <h5 class="card-title text-primary pb-2" style="font-size:0.9rem;font-weight:700;font-family:'Nunito'"> 
                                <span v-if="row.is_done === 1" class="badge rounded-pill bg-success" style="height:10px; width:10px;display:inline-block"></span>
                                <span v-else class="badge rounded-pill bg-danger" style="height:10px; width:10px;display:inline-block"></span>
                            <span style="text-transform:uppercase;" class="pl-2">{{row.company_name}}</span>  
                        </h5>
                        <div class="mt-n3 pl-2 d-flex justify-content-start flex-wrap" style="font-weight:500;text-transform:uppercase;font-size:0.75rem; color:rgba(62, 69, 81, .8);">
                            <span class=" item-mobile-spa">{{row.company_ifu}}</span>
                            <span class=" item-mobile-spa">{{row.company_rccm}}</span>
                            <span class=" item-mobile-spa">{{row.code_commercial}}</span>
                        </div> 
                    </div>
                    <div class="action">
                        <button class="btn-mob" @click="showAction(row)" >
                            <i class="fas fa-ellipsis-v"></i>
                        </button>
                    </div>    
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <tec-pagination
                    v-model="dataPagination.pagination.currentPage"
                    :per-page="parseFloat(dataPagination.pagination.perPage)"
                    :total="dataPagination.pagination.total"
                    v-on:changePageEvent="loadEnrolmentsBack"
                ></tec-pagination>
            </div>
        </div>
        <mdb-modal  color="primary" centered size="sm" position="bottom" direction="bottom" :show="modalAction.show" @close="hideAction()">
            <mdb-modal-body class="px-0 py-0 "  >
                <mdb-list-group flush>
                    <mdb-list-group-item  
                    class="bg-primary white-text font-weight-bold" > 
                    <div>MENU</div> 
                    <button class="btn-mob" @click="hideAction()"> 
                    <i class="fas fa-times"></i> 
                    </button> 
                </mdb-list-group-item>
                <mdb-list-group-item  
                    class="menu-tec-lg"
                    @click.native="details(modalAction.content)"
                    icon="align-center"  style="font-weight:400;">
                    <div class="pl-3 menu-mob-tec">Details de l'entreprise </div>
                    </mdb-list-group-item>
                </mdb-list-group>
            </mdb-modal-body>
        </mdb-modal>
    </div>
</template>

<script>
import TecPagination from '@/tba/components/PaginationUrl'
import {
    mdbModal,  mdbModalBody,
    mdbListGroup, mdbListGroupItem ,
} from 'mdbvue'
export default {
    components:{
        mdbModal,  mdbModalBody,
        mdbListGroup, mdbListGroupItem ,
        TecPagination
    },

    props:{
        empty:{
            type:Boolean,
            default:false
        },
        loading:{
            type:Boolean,
            default:false
        },
        dataPagination:{
            type:Array,
            default: ()=>[]
        },
        data:{
            type:Array,
            default:() => []
        },
        queriedData:{
            type: Array,
            default: () => []
        },
    },

    data(){
        return {
            modalAction:{
                show:false,
                content:{}
            }
        }
    },
    methods:{
        showAction(data){
            this.modalAction.content  = data
            this.modalAction.show = true
        },

        hideAction(){
            this.modalAction.content  = {}
            this.modalAction.show = false
        },

        details(data){
            this.hideAction()
            this.$emit('show_details',data)
        },

        loadEnrolmentsBack(e){
            this.$emit('load_enrolments_back',{
                page:e,
                perPage:this.dataPagination.pagination.perPage
            })
        },
    }
}
</script>

<style lang="scss" >

</style>