<template>
    <div class="container-fluid">
        <list-desktop
            v-on:add_or_edit="addOrEdit"
            v-if="!$isMobile()"
            :empty="empty"
            :loading="loading"
            :data="data"
            :queriedData="queriedData"
            :dataPagination="getP"
            v-on:delete_commercial="deleteCommercial"
            v-on:per_page_selected="nbrPerPageSelected"
            v-on:load_enrolments_back="loadEnrolmentBack"
            v-on:show_details="details"
        >
        </list-desktop>
        <list-mobile
            v-on:add_or_edit="addOrEdit"
            v-on:delete_commercial="deleteCommercial"
            v-on:per_page_selected="nbrPerPageSelected"
            v-on:load_enrolments_back="loadEnrolmentBack"
            v-on:show_details="details"
            v-if="$isMobile()"
            :empty="empty"
            :loading="loading"
            :data="data"
            :queriedData="queriedData"
            :dataPagination="getP"
        >
        </list-mobile>
        <mdb-modal centered    :show="modalDetails.show" @click="modalDetails.show = false">
            <mdb-modal-body class="modal-tec px-0 card-reversed">
                <div class="modal-times-tec "  @click="modalDetails.show = false"> <i class="fas fa-times"></i> </div>
                <mdb-modal-title style="font-weight:700" class="pl-2">
                    <span v-if="modalDetails.content.is_done === 1" class="badge rounded-pill bg-success" style="height:10px; width:10px;display:inline-block"></span>
                    <span v-else class="badge rounded-pill bg-danger" style="height:10px; width:10px;display:inline-block"></span>
                {{ modalDetails.title }}</mdb-modal-title>

                <br>

                <table class="modal-details">
                    <tbody>
                        <tr>
                            <th>Entreprise</th>
                            <td>
                                <div>
                                    
                                    {{modalDetails.content.company_name}}
                                </div>
                                <div class="text-grey " style="font-size:0.8rem !important">
                                    {{modalDetails.content.company_slogan}}
                                </div>
                            
                            </td>
                        </tr>
                        <tr>
                            <th>IFU</th>
                            <td>{{modalDetails.content.company_ifu}}</td>
                        </tr>
                        <tr>
                            <th>RCCM</th>
                            <td>{{modalDetails.content.company_rccm}}</td>
                        </tr>
                        <tr>
                            <th>Régime Fiscal</th>
                            <td>{{modalDetails.content.company_regim_fiscal}}</td>
                        </tr>
                        <tr>
                            <th>Contact Entreprise</th>
                            <td>
                                <div>
                                    {{modalDetails.content.company_email}}
                                </div>
                                <div >
                                    {{modalDetails.content.company_phone}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Nombre de Site</th>
                            <td>{{modalDetails.content.company_nbr_site}}</td>
                        </tr>
                        <tr>
                            <th colspan="2" class="text-center">
                                Administrateur
                            </th>
                        </tr>
                        <tr>
                            <th>Nom & Prénoms</th>
                            <td>{{modalDetails.content.person_lastname+" "+modalDetails.content.person_firstname}} <br>({{modalDetails.content.person_title}})</td>
                        </tr>
                        <tr>
                            <th>Contact Admin</th>
                            <td>
                                <div>
                                    {{modalDetails.content.person_email}}
                                </div>
                                <div >
                                    {{modalDetails.content.person_phone}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Commercial</th>
                            <td>
                                <div>
                                    {{modalDetails.content.code_commercial}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-end mt-2">
                    <mdb-btn  v-if="modalDetails.content.is_done === 0" @click="configure" size="md" color="primary" type="submit"  :disabled="modalDetails.btn" >
                    <span v-if="!modalDetails.btn">Configurer</span>
                        <span v-if="modalDetails.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-if="modalDetails.btn" class="pl-2">Loading...</span>
                    </mdb-btn>
                    <mdb-btn size="md" color="grey" @click="modalDetails.show = false">Fermer</mdb-btn>
                </div>
            </mdb-modal-body>
        </mdb-modal>
    </div>
</template>

<script>
import ListDesktop from '@/tba/views/inscriptions/modules/ListDesktop'
import ListMobile from '@/tba/views/inscriptions/modules/ListMobile'
import {
    mdbBtn,
    mdbModal,mdbModalTitle,mdbModalBody,
} from 'mdbvue'
import ranString from 'randomstring'

export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - COMMERCIAUX',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbBtn,
        mdbModal,mdbModalTitle,mdbModalBody,
        ListDesktop,ListMobile
    },

    data(){
        return {
            loading:false,
            empty:false,
            dataPagination: {
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"10",value:"10", selected: true},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",

                empty:false,

                propsToSearch: [ 
                   "company_name",
                   "company_ifu",
                   "company_rccm",
                   "company_regim_fiscal",
                   "code_commercial",
                ],
            },

            data:{
                columns: [
                    {
                        label: "#",
                        field: "created_at",
                        sort: true,
                    },
                    {
                        label: "Entreprise",
                        field: "company_name",
                        sort: true,
                    },
                    {
                        label: "IFU",
                        field: "company_ifu",
                        sort: true,
                    },
                    {
                        label: "RCCM",
                        field: "company_rccm",
                        sort: true,
                    },
                    {
                        label: "Régime Fiscal",
                        field: "company_regim_fiscal",
                        sort: true,
                    },
                    {
                        label: "Commercial",
                        field: "code_commercial ",
                        sort: true,
                    },
                    {
                        label: "",
                        field: "action",
                        sort: true,
                    },
                ],
                enrolments:{
                    current_page:1,
                    data:[]
                }
            },

            form:{
                id:"",
                email:"",
                phone:"",
                lastname:"",
                firstname:"",
                code_commercial:""
            },

            modalDetails: {
                show: false,
                edit: false,
                btn: false,
                title: "Détails",
                content: {},
            },
        }
    },

    methods:{
        async configure(){
            if(this.modalDetails.content.is_done === 1){
                this.$notify({
                    message: "Entreprise a déjà été Configurée",
                    type: 'success'
                })
                return
            }
            let res = await this.$store.dispatch("swal/ultimate",{
                 title:"Etes-vous sûr ?",
                 message:"En cliquant sur oui, un compte entreprise sera créer et envoyer à l'administrateur désigné.",
                 icon:"warning",
                 type:"warning"
             });
            if (res.isDismissed) {
                return;
            }

            this.modalDetails.btn = !this.modalDetails.btn
            this.$nprogress.start()
            let pass =  ranString.generate(8)

            let  form = {
                id:"",
                name:this.modalDetails.content.company_name,
                ifu:this.modalDetails.content.company_ifu,
                rccm:this.modalDetails.content.company_rccm,
                phone:this.modalDetails.content.company_phone,
                email:this.modalDetails.content.company_email,
                address:"",
                via_enrolments:1,

                user:{
                    firstname:this.modalDetails.content.person_firstname,
                    lastname:this.modalDetails.content.person_lastname,
                    phone:this.modalDetails.content.person_phone,
                    email:this.modalDetails.content.person_email,
                    password:pass,
                    password_c:pass,
                    password_default:pass,
                    is_active:true,
                    is_archived:false,
                },

                setting:{
                        id:"",
                        regime_fiscal:this.modalDetails.content.company_regim_fiscal,
                        nim:"",
                        signatory:"",
                        signatory_title:"",
                        digital_signature:"",
                        is_automatic:false,
                        bank:"",
                        iban:"",
                        swift:"",
                        bank_address:"",
                        bank_is_active:false
                    }
            }

            await this.$store.dispatch('company/save', form)
			.then(() => {
                this.$nprogress.done()
				this.modalDetails.show = false
                this.modalDetails.btn = !this.modalDetails.btn
                this.$notify({
                    message: "Entreprise Configurée",
                    type: 'success'
                })
                this.loadEnrolments({
                    page:this.data.enrolments.current_page,
                    perPage:this.dataPagination.pagination.perPage
                })
			})
			.catch((error) => {
                this.$nprogress.done()
                this.modalDetails.btn = !this.modalDetails.btn
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			}) 
        },
        details(row) {
            this.modalDetails.content = row

            this.modalDetails.title = `${row.company_name}`

            this.modalDetails.show = true
            // 
        },
        async loadEnrolments({
            page,
            perPage
        }){
            this.$nprogress.start()
            this.loading = !this.loading
            await this.$store.dispatch('enrolment/findAll',{
                page:page,
                perPage:perPage
            })
            .then((response)=>{
                this.$nprogress.done()
                if(response.data.enrolments.data.length > 0){
                    this.empty = false
                }else{
                    this.empty = true
                }

                this.loading = !this.loading
                
                this.data.enrolments = response.data.enrolments
                this.dataPagination.pagination.currentPage = response.data.enrolments.current_page
                this.dataPagination.pagination.perPage = response.data.enrolments.per_page
                this.dataPagination.pagination.total = response.data.enrolments.total
            })
            .catch((error)=>{
                this.$nprogress.done()
                this.loading = !this.loading

                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        loadEnrolmentBack(e){
            this.loadEnrolments({
                page:e.page,
                perPage:e.perPage
            })
        },

        // Systeme de datatable
        totalCalcul () {
            return this.dataPagination.pagination.total = this.data.enrolments.total;
        },

        searchQ () {
            if (!this.dataPagination.searchQuery) {
                // return this.pagedData;
                return new Error('Nothing To Search')
            }
            
            let result = this.data.enrolments.data.filter((row) => {
                let isIncluded = false;
                for (let key of this.dataPagination.propsToSearch) {
                    let rowValue = "";

                    if (typeof row[key] === 'string') {
                        rowValue = row[key].toString().toLowerCase(); 
                    }

                    if (typeof row[key] === 'number') {
                        rowValue = row[key];  
                    }

                    if (
                        rowValue.toString().includes &&
                        rowValue.toString().includes(this.dataPagination.searchQuery.toString().toLowerCase())
                        ) { 
                            isIncluded = true;
                        }  
                }
                return isIncluded;
            });
            this.dataPagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(e){
            let nbr = e;
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
            this.loadInvoices({
                page:1,
                perPage:nbr
            })
           return this.dataPagination.pagination.perPage = nbr
        },

        setTotal(){
            if(this.dataPagination.searchQuery === ""){
                this.dataPagination.pagination.total = this.data.enrolments.total;
            }   
        }
    },
    
    created() {
        this.loadEnrolments({
            page:this.data.enrolments.current_page,
            perPage:this.dataPagination.pagination.perPage
        })
    },

    computed: {
        pageData () {
            return this.data.enrolments.data;
        },

        to() {
            let highBound = this.from + this.dataPagination.pagination.perPage;
            if (this.data.enrolments.total < parseInt(highBound)) {
                highBound = this.total;
            }
            return parseInt(highBound);
        },
        from() {
            return this.dataPagination.pagination.perPage * (this.dataPagination.pagination.currentPage - 1);
        },

        queriedData() {
            let result = this.searchQ();
            console.log(result)
            this.setTotal()
            return (result instanceof Error) ? this.pageData : result;
        },

        total() { 
            return this.totalCalcul();
        },

        getP(){
            return this.dataPagination
        }
    }
}
</script>